/*jshint -W117 */
'use strict';

// underscore.js's stand alone debounce function
function debounce(func, wait, immediate) {
    var timeout;
    return function () {
        var context = this, args = arguments;
        var later = function () {
            timeout = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };
        var callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) { func.apply(context, args); }
    };
}

// Smooth Scrolling function and site class
function scrollToElement(selector, time, verticalOffset) {
    time = typeof time !== 'undefined' ? time : 1000;
    verticalOffset = typeof verticalOffset !== 'undefined' ? verticalOffset : 0;
    var element = $(selector),
        offset = element.offset(),
        offsetTop = offset.top + verticalOffset;

    $('html, body').stop().animate({
        scrollTop: offsetTop
    }, time);
}

// anything using the "scroll-link" class will have a smooth scroll
$('.scroll-link').click(function (e) {
    e.preventDefault();
    var target = $(this.hash);
    target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
    if (target.length) {
        scrollToElement(target, 600, -10);
        return false;
    }
});

define("scripts", function(){});

